import React from 'react';

const PortmoneForm = ({purchaseId, amountMoney, lang}) => {

    const HOST_URL = process.env.NODE_ENV !== 'production' ? 'http://localhost:5000' : process.env.REACT_APP_HOST_URL;

    const URL = 'https://www.portmone.com.ua/gateway/'
    const SUCCESS_URL = `${HOST_URL}/payment/portmone/success`
    const FAILURE_URL = `${HOST_URL}/payment/portmone/fail`

    return (
        <form action={URL} method="post" id="portmone-form">
            <input type="hidden" name="payee_id" value="26242" />
            <input type="hidden" name="shop_order_number" value={purchaseId} />
            <input type="hidden" name="bill_amount" value={amountMoney} />
            <input type="hidden" name="success_url" value={`${SUCCESS_URL}?purchase_id=${purchaseId}`} />
            <input type="hidden" name="failure_url" value={`${FAILURE_URL}?purchase_id=${purchaseId}`} />
            <input type="hidden" name="lang" value={lang} />
            <input type="hidden" name="encoding"  value= "UTF-8" />
            <input type="hidden" name="exp_time"  value= "300" />
        </form>
    )
}

export default PortmoneForm;
