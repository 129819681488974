import React from 'react';
import Loader from "react-loader-spinner";
import {Link, Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import {translate, getLanguage} from "react-switch-lang";
import PortmoneForm from '../portmone/portmone';

class Water extends React.Component {

    state = {
        amountWater: '',
        amountMoney: '',
        loading: true,
        avtomatId: this.props.match.params.id,
        address: null,
        priceForApp: null,
        error: null,
        maxLitres: 22,
        purchaseId: '',
        isSubmitButtonClick: false
    }

    componentDidMount() {
        fetch(`/api/status/${this.state.avtomatId}`)
            .then((resp) => {
                return resp.json()
            })
            .then((status) => {
                this.setState({
                    loading: false,
                    address: status.address,
                    priceForApp: status.price_for_app / 100,
                    error: status.error,
                })
            })
    }

    handleInput = (e) => {
        const { priceForApp } = this.state;
        let inputName = e.target.getAttribute('name');
        let changeState;
        switch (inputName){
            case 'amountWater' :
                changeState = {amountWater: parseFloat(e.target.value),
                               amountMoney: e.target.value ? (e.target.value * priceForApp).toFixed(2) : ''
                };
                break;
            case 'amountMoney' :
                changeState = {amountMoney: e.target.value,
                               amountWater: e.target.value ? parseFloat(e.target.value / priceForApp).toFixed(2) : ''
                };
                break;
            default:
        }
        this.setState(changeState);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.isSubmitButtonClick) {
            return;
        }
        this.setState({isSubmitButtonClick: true})
        fetch('/api/purchase', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                avtomatId: this.state.avtomatId,
                address: this.state.address,
                amountMoney: this.state.amountMoney
            })
        })
        .then((resp) => {
            return resp.json()
        })
        .then(({ purchase_id }) => {
            this.setState({purchaseId: purchase_id})
            document.getElementById('portmone-form').submit()
        })
    };

    loader = () => {
        return (
            <Loader
                type="Puff"
                color="#1c93da"
                height={100}
                width={100}
                timeout={2000} //2 sec
            />
        )
    }

    purchaseForm = () => {
        const { t } = this.props;

        const { amountWater, amountMoney, priceForApp, maxLitres } = this.state;
        const maxSum = (maxLitres * priceForApp).toFixed(2);

        return (
            <form onSubmit={this.handleSubmit} id="payment-form" method="post">
                <h3 className='water-form__title'>{t('water.secondTitle')}</h3>

                <div className='show-error water-form-error'>
                    <p>{`${t('water.allowableVolume')}: 1 - ${maxLitres}.
                         ${t('water.allowableSum')}: ${priceForApp} - ${maxSum}`}
                    </p>
                </div>

                <div className='water-form__row'>
                    <label
                        className={'water-form__input input-liters ' + (getLanguage())} >
                        <input type="number"
                            autoFocus={true}
                            min={1}
                            max={maxLitres}
                            pattern="[0-9]+([\.,][0-9]+)?"
                            step="0.01" maxLength="4"
                            value={amountWater} name="amountWater"
                            onChange={this.handleInput} required/>
                    </label>
                    <label
                        className={'water-form__input input-price ' + (getLanguage())}>
                        <input type="number"
                            min={priceForApp} max={maxSum}
                            pattern="[0-9]+([\.,][0-9]+)?"
                            step="0.01" maxLength="4"
                            value={amountMoney} name="amountMoney"
                            onChange={this.handleInput} required/>
                    </label>
                </div>

                <div className='water-form__input-submit'>
                    <button type="submit" disabled={this.state.isSubmitButtonClick}>
                        { !this.state.isSubmitButtonClick ? t('water.submitText') : t('water.wait') }
                    </button>
                </div>
            </form>
        )
    }

    render() {
        if (this.state.error) {
            return <Redirect to={`/ready/${this.state.avtomatId}`} />
        }

        const { t } = this.props;
        const { loading, address } = this.state;

        const content = loading ? <div className='ready-content'>{this.loader()}</div> : this.purchaseForm();

        return (
            <div className='water-background'>
                <div className='water-container'>
                    <div className="home-btn">
                        <Link to={'/'}>
                            <FontAwesomeIcon icon={faHome} />
                        </Link>
                    </div>
                    <div className='water-address'>
                        <h2 className='water-title__address'>{t('water.addressText')}</h2>
                        <div className='water-location__address'>
                            <span>{address}</span>
                        </div>
                    </div>
                    {content}
                    <PortmoneForm purchaseId={this.state.purchaseId}
                                  amountMoney={this.state.amountMoney}
                                  lang={getLanguage()}
                    />
                </div>
            </div>
        );
    }
}

Water.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Water);
