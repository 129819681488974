import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Home from '../home';
import Ready from '../ready';
import Water from '../water';
import AfterPayment from '../after-payment';
import Issue from '../issue';
import Nearest from '../nearest';
import AddToHomescreen from '../add-to-home-screen';

import './app.css';

import {
    setTranslations,
    setLanguageCookie,
    setLanguage,
    getLanguage,
    setDefaultLanguage
} from 'react-switch-lang';
import en from './translation/en.json';
import ru from './translation/ru.json';
import uk from './translation/uk.json';

setTranslations({en, ru, uk});
setDefaultLanguage('uk')

setLanguageCookie();

export default class App extends React.Component {
    constructor(props) {
        super(props)
        let lang = getLanguage()
        let cookieLang = this.getCookie('language')
        if (cookieLang && cookieLang.length > 1) {
            lang = cookieLang
        }
        setLanguage(lang);
        this.state = {
            lang: lang
        }
    }

    handleSetLanguage = (key) => () => {
        setLanguage(key);
        this.setState({lang: key})
    };

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    componentDidCatch(error, info) {
        console.log(error, info)
    }

    render() {
        let currentLng = this.state.lang
        return (
            <>
                <div className={'lang-switch'} id="lang-switch">
                    <button type="button" className={currentLng === 'uk' ? 'active' : ''}
                            onClick={this.handleSetLanguage('uk')}>
                        UA
                    </button>
                    /
                    <button type="button" className={currentLng === 'ru' ? 'active' : ''}
                            onClick={this.handleSetLanguage('ru')}>
                        RU
                    </button>
                    /
                    <button type="button" className={currentLng === 'en' ? 'active' : ''}
                            onClick={this.handleSetLanguage('en')}>
                        EN
                    </button>
                </div>
                <AddToHomescreen />
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/ready/:id" component={Ready} />
                        <Route path="/water/:id" component={Water} />
                        <Route path="/water-portmone/:id" component={Water} />
                        <Route path="/after-payment/:id" component={AfterPayment} />
                        <Route path="/issue/:id" component={Issue} />
                        <Route path="/nearest/:id" component={Nearest} />
                    </Switch>
                </BrowserRouter>
            </>
        );
    }
}
