import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import {translate} from "react-switch-lang";

const AfterPayment = (props) => {
    const { t } = props;
    const { id } = props.match.params
    const params = queryString.parse(props.location.search);

    const Message = () => {
        const homeUrl = params.status === 'success' ? '/' : `/?id=${id}`;
        return (
            <React.Fragment>
                <h1 className='thank-title'>{t(`${params.status}AfterPayment.title`)}</h1>
                <h3 className='thank-title'>{t(`${params.status}AfterPayment.desc`)}</h3>
                <button className='thank-button' onClick={ ()=> props.history.push(homeUrl)}>
                    {t(`${params.status}AfterPayment.button`)}
                </button>
                <button className='thank-you-link' onClick={ ()=> props.history.push(`/issue/${id}`)}>
                    {t(`${params.status}AfterPayment.issue`)}
                </button>
            </React.Fragment>
        )
    }

    return (
        <div className="thank-background">
            <div className="home-btn">
                <Link to={'/'}>
                    <FontAwesomeIcon icon={faHome} />
                </Link>
            </div>
            <div className='thank-container'>
                <div className="thank-content">
                    <Message />
                </div>
            </div>
        </div>
    )
}

AfterPayment.propTypes = {
	t: PropTypes.func.isRequired,
};

export default translate(AfterPayment);
