import React from 'react';
import {Link} from "react-router-dom";
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import PropTypes from "prop-types";
import {translate} from "react-switch-lang";

class Ready extends React.Component {

    state = {
        loading: true,
        avtomatId: this.props.match.params.id,
        address: '',
        paymentGatewayName: '',
        error: null,
    }

    componentDidMount() {
        fetch(`/api/status/${this.state.avtomatId}`)
            .then((resp) => {
                return resp.json()
            })
            .then((status) => {
                this.setState({
                    loading: false,
                    address: status.address,
                    paymentGatewayName: status.payment_gateway_name,
                    error: status.error
                })
            })
    }

    render() {
        const { t } = this.props;
        const { loading } = this.state;

        const spinner = loading ? this.loader() : null;

        let content = null;
        switch(this.state.error) {
            case false:
                content = this.renderForm();
                break;
            case 'notFound':
                content = this.renderReport(t('closed.notFound'));
                break;
            case 'notWorking':
                content = this.renderReport(t('closed.notWorking'));
                break;
            case 'cashOnly':
                content = this.renderReport(t('closed.cashOnly'));
                break;
            case 'avtomatIsBusy':
                content = this.renderReport(t('closed.avtomatIsBusy'));
                break;
            default:
        }

        return (
            <div className='ready-background'>
                <div className='ready-container'>
                    <div className="home-btn">
                        <Link to={'/'}>
                            <FontAwesomeIcon icon={faHome} />
                        </Link>
                    </div>
                    <div className='ready-address'>
                        <h1 className='ready-title'>{t('ready.addressText')}</h1>
                        <div className='ready-location__address'>
                            <span>{this.state.address}</span>
                        </div>
                    </div>
                    {spinner}
                    {content}
                </div>
            </div>
        );
    }

    loader() {
        return (
            <div className="ready-content">
                <Loader
                    type="Puff"
                    color="#1c93da"
                    height={100}
                    width={100}
                    timeout={2000} //2 sec
                />
            </div>
        )
    }

    renderForm() {
        const { t } = this.props;
        return (
            <div className="ready-content">
                <h1 className='ready-title__content'>{t('ready.readyTitle')}</h1>
                <Link to={'/water/' + this.state.avtomatId} className='ready-button'>
                    {t('ready.button')}
                </Link>
            </div>
        )
    }


    renderReport(message) {
        const { t } = this.props;
        return (
            <div className="closed-content">
                 <div className='closed-content--message'>
                    <p className='closed-content--text'>
                        {message}
                    </p>
                </div>
                {this.state.error === 'notWorking' ?
                    <div className='closed-location--text'>
                        <Link to={'/nearest/' + this.state.avtomatId}>
                            <span>{t('closed.vodomatLocation')}</span>
                        </Link>
                    </div>
                    : ''
                }
            </div>
        )
    }

}

Ready.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Ready);
