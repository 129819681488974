import React from 'react';
import queryString from 'query-string';
import QrReader from 'react-qr-reader'
import PropTypes from 'prop-types';

import {
    translate,
} from 'react-switch-lang';

class Dashboard extends React.Component {
    
    state = {
        camera: false,
        avtomatId: ''
    }

    componentDidMount = () => {
        const params = queryString.parse(this.props.location.search)
        if (params.vodomat_id) {
            this.setState({
                avtomatId: params.vodomat_id
            })
        }
    }

    onAvtomatIdChange = (e) => {
        this.setState({
            avtomatId: e.target.value
        });
    };

    onAvtomatIdKeyDown = (e) => {
        (e.keyCode === 69 ||
         e.keyCode === 190 ||
         e.keyCode === 188 ||
         e.keyCode === 189 ||
         e.keyCode === 187) &&
        e.preventDefault()
    }

    onSubmitForm = (e) => {
        e.preventDefault();

        let avtomatId = this.state.avtomatId;
        if (avtomatId.length <= 0) {
            document.querySelector("input").style.borderColor = "red";
        } else if (avtomatId.length > 0) {
            this.props.history.push('/ready/' + avtomatId);
        }
    };

    onButtonRenderCamera = () => {
        this.setState({
            camera: true,
        });
        document.getElementById('lang-switch').style.display = "none";
        document.getElementById('home-qr--block').style.display = "none";
    };

    onButtonIssue = () => {
        if (this.state.avtomatId) {
            this.props.history.push(`/issue/${this.state.avtomatId}`);
        } else {
            document.querySelector("input").style.borderColor = "red";
        }
    }

    handleScan = data => {
        if(window.location.href === data)
            return;

        if (data) {
            window.location.href = data;
        }
    }

    renderScanner() {
        return (
            <QrReader
                delay={1000}
                onError={(err) => console.log(err)}
                onScan={this.handleScan}
                style={{ width: '100%' }}
                facingMode={'environment'}
            />
        )
    }

    render() {
        const { t } = this.props;
        return (
            <div className='home-background'>
                <div className='home-container'>
                    <div className='home-advise'>{t('home.advise')}</div>
                    <h1 className='home-title'>{t('home.title')}</h1>
                    <div className='home-form'>
                        <form className='home-form_container' onSubmit={this.onSubmitForm.bind(this)}>
                            <label>
                                <input
                                    type="number"
                                    min="1"
                                    step='1'
                                    onChange={this.onAvtomatIdChange}
                                    value={this.state.avtomatId}
                                    onKeyDown={this.onAvtomatIdKeyDown}
                                />
                            </label>
                            <span>{t('home.or')}</span>
                            <div className='home-qr--block' id='home-qr--block'>
                                <div onClick={() => this.onButtonRenderCamera()}>
                                    {t('home.inputCamera')}
                                </div>
                            </div>
                            {!this.state.camera ? '' : this.renderScanner()}
                            <div className='home-next'>
                                <button>{t('home.inputSubmit')}</button>
                            </div>
                            <div className='issue-btn' onClick={this.onButtonIssue}>
                                {t('home.report')}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Dashboard);
