import React, { useEffect, useState } from "react";
import iosAdd from "../app/images/ios_add.svg"
import iosShare from "../app/images/ios_share.svg"
import PropTypes from "prop-types";
import {translate} from "react-switch-lang";

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);


const AddToHomescreen = (props) => {
  const [visible, setVisible] = useState(false);
  const { t } = props;

  useEffect(() => {
    if (isIos() && !isInStandaloneMode()) {
      setVisible(true);
    }
  }, []);

  return (
    <>
      {visible && (<div style={{
        position: "fixed",
        display: "flex",
        bottom: "15px",
        zIndex: 1000,
        width: "100%",
        background: "#EFFAFC",
        color: "#484848",
        borderRadius: "8px",
        padding: "10px",
        boxSizing: "border-box",
        fontFamily: "var(--helvetica-regular)"
      }}>
        <div style={{
          width: 0,
          height: 0,
          borderStyle: "solid",
          borderWidth: "14px 14px 0 14px",
          borderColor: "#EFFAFC transparent transparent transparent",
          position: "fixed",
          left: "calc(50% - 14px)",
          bottom: "5px"
        }}/>
        <div style={{
          display: "flex",
          alignItems: "center",
          paddingRight: "5px"
        }}>
          <div style={{
            border: "1px solid #7d8c8d",
            borderRadius: "4px",
            background: "#7d8c8d",
            height: "23px",
            width: "23px"
          }}>
            <img src={iosAdd} style={{ height: "23px", width: "23px" }} alt=''/>
          </div>
        </div>
        <div>
          {t('install.title')} {" "} <img src={iosShare} style={{ height: "20px", verticalAlign: "middle" }} alt=''/>
          {" "}
          {t('install.sub')}
        </div>
      </div>)}
    </>
  );
};

AddToHomescreen.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate(AddToHomescreen);


